import Service from '../Service'

export default class extends Service {
  constructor (id) {
    const url = ['system', 'users']
    if (id) {
      url.push(id)
    }
    super(url)
  }

  activate () {
    return this.post(null, '/activate')
  }

  deactivate () {
    return this.post(null, '/deactivate')
  }

  resetPassword (data) {
    return this.post(data, '/resetPassword')
  }

  uploadAvatar (data) {
    return this.upload(data, '/upload')
  }
}
