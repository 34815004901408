<template>
  <gf-base>
    <gf-card :title="title" :subtitle="subtitle">
      <el-dialog title="Add New Position" :visible.sync="addPositionModalVisible" append-to-body v-loading="addPositionModalLoading" :close-on-click-modal="false">
        <gf-form>
          <template #alert>
            <gf-alert>
              <template #icon>
                <span class="svg-icon svg-icon-primary svg-icon-md">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24"/>
                      <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                      <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                    </g>
                  </svg>
                </span>
              </template>
              Input the name of the position below
            </gf-alert>
          </template>
          <el-form label-width="125px" :model="position" ref="position" :rules="positionRules">
            <el-form-item label="Position Name" prop="name">
              <el-input v-model="position.name"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <gf-button type="secondary" @click="addPositionModalVisible = false">
              <template slot="icon">
                <span class="svg-icon svg-icon-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                        <rect x="0" y="7" width="16" height="2" rx="1"/>
                        <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                      </g>
                    </g>
                  </svg>
                </span>
              </template>
              Close
            </gf-button>
            <gf-button type="primary" @click="newPosition">
              <template slot="icon">
                <span class="svg-icon svg-icon-white svg-icon-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/>
                      <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1"/>
                    </g>
                  </svg>
                </span>
              </template>
              Add
            </gf-button>
          </template>
        </gf-form>
      </el-dialog>
      <el-dialog title="Deactivate User" :visible.sync="deactivateModalVisible" append-to-body v-loading="deactivateModalLoading" :close-on-click-modal="false">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-warning svg-icon-xxl">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M19.0710678,4.92893219 L19.0710678,4.92893219 C19.4615921,5.31945648 19.4615921,5.95262146 19.0710678,6.34314575 L6.34314575,19.0710678 C5.95262146,19.4615921 5.31945648,19.4615921 4.92893219,19.0710678 L4.92893219,19.0710678 C4.5384079,18.6805435 4.5384079,18.0473785 4.92893219,17.6568542 L17.6568542,4.92893219 C18.0473785,4.5384079 18.6805435,4.5384079 19.0710678,4.92893219 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                </g>
              </svg>
            </span>
          </template>
          You are trying to deactivate this user. User will not be able to login until you reactivate this user. Are you sure?
        </gf-alert>
        <template #footer>
          <gf-button type="secondary" class="mr-2" @click="deactivateModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="warning" @click="deactivate">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M19.0710678,4.92893219 L19.0710678,4.92893219 C19.4615921,5.31945648 19.4615921,5.95262146 19.0710678,6.34314575 L6.34314575,19.0710678 C5.95262146,19.4615921 5.31945648,19.4615921 4.92893219,19.0710678 L4.92893219,19.0710678 C4.5384079,18.6805435 4.5384079,18.0473785 4.92893219,17.6568542 L17.6568542,4.92893219 C18.0473785,4.5384079 18.6805435,4.5384079 19.0710678,4.92893219 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                  </g>
                </svg>
              </span>
            </template>
            Deactivate
          </gf-button>
        </template>
      </el-dialog>
      <el-dialog title="Ativate User" :visible.sync="activateModalVisible" append-to-body v-loading="activateModalLoading" :close-on-click-modal="false">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-primary svg-icon-xxl">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24"/>
                  <path d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z" fill="#000000" fill-rule="nonzero" transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "/>
                </g>
              </svg>
            </span>
          </template>
          You are trying to reactivate this user. User will be able to login. Are you sure?
        </gf-alert>
        <template #footer>
          <gf-button type="secondary" class="mr-2" @click="activateModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="primary" @click="activate">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"/>
                    <path d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z" fill="#000000" fill-rule="nonzero" transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "/>
                  </g>
                </svg>
              </span>
            </template>
            Activate
          </gf-button>
        </template>
      </el-dialog>
      <el-dialog title="Reset Password" :visible.sync="resetModalVisible" append-to-body v-loading="resetModalLoading" :close-on-click-modal="false">
        <gf-form>
          <template #alert>
            <gf-alert>
              <template #icon>
                <span class="svg-icon svg-icon-success svg-icon-xxl">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path d="M7.38979581,2.8349582 C8.65216735,2.29743306 10.0413491,2 11.5,2 C17.2989899,2 22,6.70101013 22,12.5 C22,18.2989899 17.2989899,23 11.5,23 C5.70101013,23 1,18.2989899 1,12.5 C1,11.5151324 1.13559454,10.5619345 1.38913364,9.65805651 L3.31481075,10.1982117 C3.10672013,10.940064 3,11.7119264 3,12.5 C3,17.1944204 6.80557963,21 11.5,21 C16.1944204,21 20,17.1944204 20,12.5 C20,7.80557963 16.1944204,4 11.5,4 C10.54876,4 9.62236069,4.15592757 8.74872191,4.45446326 L9.93948308,5.87355717 C10.0088058,5.95617272 10.0495583,6.05898805 10.05566,6.16666224 C10.0712834,6.4423623 9.86044965,6.67852665 9.5847496,6.69415008 L4.71777931,6.96995273 C4.66931162,6.97269931 4.62070229,6.96837279 4.57348157,6.95710938 C4.30487471,6.89303938 4.13906482,6.62335149 4.20313482,6.35474463 L5.33163823,1.62361064 C5.35654118,1.51920756 5.41437908,1.4255891 5.49660017,1.35659741 C5.7081375,1.17909652 6.0235153,1.2066885 6.2010162,1.41822583 L7.38979581,2.8349582 Z" fill="#000000" opacity="0.3"/>
                      <path d="M14.5,11 C15.0522847,11 15.5,11.4477153 15.5,12 L15.5,15 C15.5,15.5522847 15.0522847,16 14.5,16 L9.5,16 C8.94771525,16 8.5,15.5522847 8.5,15 L8.5,12 C8.5,11.4477153 8.94771525,11 9.5,11 L9.5,10.5 C9.5,9.11928813 10.6192881,8 12,8 C13.3807119,8 14.5,9.11928813 14.5,10.5 L14.5,11 Z M12,9 C11.1715729,9 10.5,9.67157288 10.5,10.5 L10.5,11 L13.5,11 L13.5,10.5 C13.5,9.67157288 12.8284271,9 12,9 Z" fill="#000000"/>
                    </g>
                  </svg>
                </span>
              </template>
              Enter the new password of the user below.
            </gf-alert>
          </template>
          <el-form label-width="150px" ref="resetPassword" :model="reset" :rules="passwordResetRules">
            <el-form-item label="Password" prop="password">
              <el-input type="password" v-model="reset.password"></el-input>
            </el-form-item>
            <el-form-item label="Retype Password" prop="retype_password">
              <el-input type="password" v-model="reset.retype_password"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <gf-button type="secondary" class="mr-2" @click="resetModalVisible = false">
              <template slot="icon">
                <span class="svg-icon svg-icon-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                        <rect x="0" y="7" width="16" height="2" rx="1"/>
                        <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                      </g>
                    </g>
                  </svg>
                </span>
              </template>
              Close
            </gf-button>
            <gf-button type="success" @click="resetPassword">
              <template slot="icon">
                <span class="svg-icon svg-icon-white svg-icon-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path d="M7.38979581,2.8349582 C8.65216735,2.29743306 10.0413491,2 11.5,2 C17.2989899,2 22,6.70101013 22,12.5 C22,18.2989899 17.2989899,23 11.5,23 C5.70101013,23 1,18.2989899 1,12.5 C1,11.5151324 1.13559454,10.5619345 1.38913364,9.65805651 L3.31481075,10.1982117 C3.10672013,10.940064 3,11.7119264 3,12.5 C3,17.1944204 6.80557963,21 11.5,21 C16.1944204,21 20,17.1944204 20,12.5 C20,7.80557963 16.1944204,4 11.5,4 C10.54876,4 9.62236069,4.15592757 8.74872191,4.45446326 L9.93948308,5.87355717 C10.0088058,5.95617272 10.0495583,6.05898805 10.05566,6.16666224 C10.0712834,6.4423623 9.86044965,6.67852665 9.5847496,6.69415008 L4.71777931,6.96995273 C4.66931162,6.97269931 4.62070229,6.96837279 4.57348157,6.95710938 C4.30487471,6.89303938 4.13906482,6.62335149 4.20313482,6.35474463 L5.33163823,1.62361064 C5.35654118,1.51920756 5.41437908,1.4255891 5.49660017,1.35659741 C5.7081375,1.17909652 6.0235153,1.2066885 6.2010162,1.41822583 L7.38979581,2.8349582 Z" fill="#000000" opacity="0.3"/>
                      <path d="M14.5,11 C15.0522847,11 15.5,11.4477153 15.5,12 L15.5,15 C15.5,15.5522847 15.0522847,16 14.5,16 L9.5,16 C8.94771525,16 8.5,15.5522847 8.5,15 L8.5,12 C8.5,11.4477153 8.94771525,11 9.5,11 L9.5,10.5 C9.5,9.11928813 10.6192881,8 12,8 C13.3807119,8 14.5,9.11928813 14.5,10.5 L14.5,11 Z M12,9 C11.1715729,9 10.5,9.67157288 10.5,10.5 L10.5,11 L13.5,11 L13.5,10.5 C13.5,9.67157288 12.8284271,9 12,9 Z" fill="#000000"/>
                    </g>
                  </svg>
                </span>
              </template>
              Reset Password
            </gf-button>
          </template>
        </gf-form>
      </el-dialog>
      <el-dialog title="Remove User" :visible.sync="removeModalVisible" append-to-body v-loading="removeModalLoading" :close-on-click-modal="false">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-danger svg-icon-xxl">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                    <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                  </g>
                </svg>
            </span>
          </template>
          You are trying to remove this user. User will not be able to login anymore. Are you sure?
        </gf-alert>
        <template #footer>
          <gf-button type="secondary" class="mr-2" @click="removeModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="danger" @click="remove">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                    <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                  </g>
                </svg>
              </span>
            </template>
            Remove User
          </gf-button>
        </template>
      </el-dialog>
      <template #toolbar>
        <gf-button v-if="$route.meta.mode === 'update' && user.is_active" class="mr-4" type="warning" @click="deactivateModalVisible = true">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sx">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M19.0710678,4.92893219 L19.0710678,4.92893219 C19.4615921,5.31945648 19.4615921,5.95262146 19.0710678,6.34314575 L6.34314575,19.0710678 C5.95262146,19.4615921 5.31945648,19.4615921 4.92893219,19.0710678 L4.92893219,19.0710678 C4.5384079,18.6805435 4.5384079,18.0473785 4.92893219,17.6568542 L17.6568542,4.92893219 C18.0473785,4.5384079 18.6805435,4.5384079 19.0710678,4.92893219 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                </g>
              </svg>
            </span>
          </template>
          Deactivate
        </gf-button>
        <gf-button v-if="$route.meta.mode === 'update' && !user.is_active" class="mr-4" type="primary" @click="activateModalVisible = true">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sx">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24"/>
                  <path d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z" fill="#000000" fill-rule="nonzero" transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "/>
                </g>
              </svg>
            </span>
          </template>
          Activate
        </gf-button>
        <gf-button v-if="$route.meta.mode === 'update'" class="mr-4" type="success" @click="resetModalVisible = true">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sx">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M7.38979581,2.8349582 C8.65216735,2.29743306 10.0413491,2 11.5,2 C17.2989899,2 22,6.70101013 22,12.5 C22,18.2989899 17.2989899,23 11.5,23 C5.70101013,23 1,18.2989899 1,12.5 C1,11.5151324 1.13559454,10.5619345 1.38913364,9.65805651 L3.31481075,10.1982117 C3.10672013,10.940064 3,11.7119264 3,12.5 C3,17.1944204 6.80557963,21 11.5,21 C16.1944204,21 20,17.1944204 20,12.5 C20,7.80557963 16.1944204,4 11.5,4 C10.54876,4 9.62236069,4.15592757 8.74872191,4.45446326 L9.93948308,5.87355717 C10.0088058,5.95617272 10.0495583,6.05898805 10.05566,6.16666224 C10.0712834,6.4423623 9.86044965,6.67852665 9.5847496,6.69415008 L4.71777931,6.96995273 C4.66931162,6.97269931 4.62070229,6.96837279 4.57348157,6.95710938 C4.30487471,6.89303938 4.13906482,6.62335149 4.20313482,6.35474463 L5.33163823,1.62361064 C5.35654118,1.51920756 5.41437908,1.4255891 5.49660017,1.35659741 C5.7081375,1.17909652 6.0235153,1.2066885 6.2010162,1.41822583 L7.38979581,2.8349582 Z" fill="#000000" opacity="0.3"/>
                  <path d="M14.5,11 C15.0522847,11 15.5,11.4477153 15.5,12 L15.5,15 C15.5,15.5522847 15.0522847,16 14.5,16 L9.5,16 C8.94771525,16 8.5,15.5522847 8.5,15 L8.5,12 C8.5,11.4477153 8.94771525,11 9.5,11 L9.5,10.5 C9.5,9.11928813 10.6192881,8 12,8 C13.3807119,8 14.5,9.11928813 14.5,10.5 L14.5,11 Z M12,9 C11.1715729,9 10.5,9.67157288 10.5,10.5 L10.5,11 L13.5,11 L13.5,10.5 C13.5,9.67157288 12.8284271,9 12,9 Z" fill="#000000"/>
                </g>
              </svg>
            </span>
          </template>
          Reset Password
        </gf-button>
        <gf-button v-if="$route.meta.mode === 'update'" class="mr-4" type="danger" @click="removeModalVisible = true">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sx">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                  <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                </g>
              </svg>
            </span>
          </template>
          Delete
        </gf-button>
        <gf-button type="secondary" @click="back">
          <template slot="icon">
            <span class="svg-icon svg-icon-sx">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24"/>
                  <path d="M4.7071045,12.7071045 C4.3165802,13.0976288 3.68341522,13.0976288 3.29289093,12.7071045 C2.90236664,12.3165802 2.90236664,11.6834152 3.29289093,11.2928909 L9.29289093,5.29289093 C9.67146987,4.914312 10.2810563,4.90106637 10.6757223,5.26284357 L16.6757223,10.7628436 C17.0828413,11.136036 17.1103443,11.7686034 16.7371519,12.1757223 C16.3639594,12.5828413 15.7313921,12.6103443 15.3242731,12.2371519 L10.0300735,7.38413553 L4.7071045,12.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(10.000001, 8.999997) scale(-1, -1) rotate(90.000000) translate(-10.000001, -8.999997) "/>
                  <path d="M20,8 C20.5522847,8 21,8.44771525 21,9 C21,9.55228475 20.5522847,10 20,10 L13.5,10 C12.9477153,10 12.5,10.4477153 12.5,11 L12.5,21.0415946 C12.5,21.5938793 12.0522847,22.0415946 11.5,22.0415946 C10.9477153,22.0415946 10.5,21.5938793 10.5,21.0415946 L10.5,11 C10.5,9.34314575 11.8431458,8 13.5,8 L20,8 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.750000, 15.020797) scale(-1, 1) translate(-15.750000, -15.020797) "/>
                </g>
              </svg>
            </span>
          </template>
          Back
        </gf-button>
      </template>
      <el-row class="content" v-loading="loading">
        <el-col class="content__nav">
          <div class="nav__item text-hover-primary bg-hover-light text mb-2" :class="{ 'bg-light': active === 1 }" @click="active = 1">
            <span class="icon mr-2">
              <span class="svg-icon" :class="{ 'svg-icon-primary': active === 1 }">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"/>
                    <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                    <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                  </g>
                </svg>
              </span>
            </span>
            <div class="label">
              <span class="text-dark font-weight-bold font-size-sm mb-1">Personal Information</span>
              <span class="font-weight-bold text-dark-50 font-size-xs">Input user personal information</span>
            </div>
          </div>
          <div v-if="$route.meta.mode === 'create'" class="nav__item text-hover-primary bg-hover-light text mb-2" :class="{ 'bg-light': active === 2 }" @click="active = 2">
            <span class="icon mr-2">
              <span class="svg-icon" :class="{ 'svg-icon-primary': active === 2 }">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z" fill="#000000" opacity="0.3"/>
                    <polygon fill="#000000" opacity="0.3" points="11.3333333 18 16 11.4 13.6666667 11.4 13.6666667 7 9 13.6 11.3333333 13.6"/>
                  </g>
                </svg>
              </span>
            </span>
            <div class="label">
              <span class="text-dark font-weight-bold font-size-sm mb-1">Account Details</span>
              <span class="font-weight-bold text-dark-50 font-size-xs">Setup account details</span>
            </div>
          </div>
          <div class="nav__item text-hover-primary bg-hover-light text mb-2" :class="{ 'bg-light': active === 3 }" @click="active = 3">
            <span class="icon mr-2">
              <span class="svg-icon" :class="{ 'svg-icon-primary': active === 3 }">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"/>
                    <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                    <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                  </g>
                </svg>
              </span>
            </span>
            <div class="label">
              <span class="text-dark font-weight-bold font-size-sm mb-1">Groups</span>
              <span class="font-weight-bold text-dark-50 font-size-xs">Add user to groups</span>
            </div>
          </div>
          <div class="nav__item text-hover-primary bg-hover-light text mb-2" :class="{ 'bg-light': active === 4 }" @click="active = 4">
            <span class="icon mr-2">
              <span class="svg-icon" :class="{ 'svg-icon-primary': active === 4 }">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                    <path d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z" fill="#000000"/>
                    <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                  </g>
                </svg>
              </span>
            </span>
            <div class="label">
              <span class="text-dark font-weight-bold font-size-sm mb-1">Permissions</span>
              <span class="font-weight-bold text-dark-50 font-size-xs">Setup users permissions</span>
            </div>
          </div>
        </el-col>
        <el-col class="content__body">
          <gf-container>
            <gf-form :width="500">
              <el-form label-width="150px" label-position="left" ref="user" :model="user" :rules="userRules">
                <div :style="{ display: active === 1 ? 'block': 'none' }">
                  <gf-alert class="mb-8">
                    <template slot="icon">
                      <span class="svg-icon svg-icon-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24"/>
                            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                          </g>
                        </svg>
                      </span>
                    </template>
                    Input User's personal information below
                  </gf-alert>
                  <el-form-item label="Avatar">
                    <div class="image-input">
                      <img v-if="user.avatar_url" :src="user.avatar_url" alt="">
                      <img v-else src="@/assets/avatar.jpg" alt="">
                      <span class="tool tool__change" @click="handleAvatarChange">
                        <i class="el-icon-edit"></i>
                        <input type="file" @change="handleFileChange" ref="avatarInputFile" accept=".png, .jpg, .jpeg">
                      </span>
                      <span class="tool tool__remove" @click="handleClearAvatar"><i class="el-icon-close"></i></span>
                    </div>
                  </el-form-item>
                  <el-form-item label="First Name" prop="fname">
                    <el-input v-model="user.fname" placeholder="First Name"></el-input>
                  </el-form-item>
                  <el-form-item label="Middle Name" prop="mname">
                    <el-input v-model="user.mname" placeholder="Middle Name"></el-input>
                  </el-form-item>
                  <el-form-item label="Last Name" prop="lname">
                    <el-input v-model="user.lname" placeholder="Last Name"></el-input>
                  </el-form-item>
                  <el-form-item label="Address" prop="address">
                    <el-input v-model="user.address" placeholder="Address"></el-input>
                  </el-form-item>
                  <el-form-item label="Position" prop="position_id">
                    <el-select style="width: 100%" v-model="user.position_id" placeholder="Position" filterable remote :remote-method="getPositions" :loading="selectPositionLoading">
                      <el-option v-for="pos in positions" :key="pos.id" :value="pos.id" :label="pos.name"></el-option>
                    </el-select>
                    <el-button class="mt-1" type="text" @click="addPositionModalVisible = true">+ Add Position</el-button>
                  </el-form-item>
                  <gf-alert class="mb-8">
                    <template slot="icon">
                      <span class="svg-icon svg-icon-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <path d="M18,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,19 C23,20.6568542 21.6568542,22 20,22 L18,22 L18,2 Z" fill="#000000" opacity="0.3"/>
                            <path d="M5,2 L17,2 C18.6568542,2 20,3.34314575 20,5 L20,19 C20,20.6568542 18.6568542,22 17,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M12,11 C13.1045695,11 14,10.1045695 14,9 C14,7.8954305 13.1045695,7 12,7 C10.8954305,7 10,7.8954305 10,9 C10,10.1045695 10.8954305,11 12,11 Z M7.00036205,16.4995035 C6.98863236,16.6619875 7.26484009,17 7.4041679,17 C11.463736,17 14.5228466,17 16.5815,17 C16.9988413,17 17.0053266,16.6221713 16.9988413,16.5 C16.8360465,13.4332455 14.6506758,12 11.9907452,12 C9.36772908,12 7.21569918,13.5165724 7.00036205,16.4995035 Z" fill="#000000"/>
                          </g>
                        </svg>
                      </span>
                    </template>
                    Input User's Contact Information below
                  </gf-alert>
                  <el-form-item label="Contact No" prop="phone_number">
                    <el-input prefix-icon="el-icon-phone" v-model="user.phone_number" placeholder="Phone Number"></el-input>
                  </el-form-item>
                  <el-form-item label="Email" prop="email">
                    <el-input prefix-icon="el-icon-message" v-model="user.email" placeholder="Email"></el-input>
                  </el-form-item>
                  <div class="control">
                    <gf-button type="primary" @click="$route.meta.mode === 'create' ? active++ : active+= 2 ">
                      <template slot="icon">
                        <span class="svg-icon svg-icon-white svg-icon-sx">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <polygon points="0 0 24 0 24 24 0 24"/>
                              <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) " x="11" y="5" width="2" height="14" rx="1"/>
                              <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) "/>
                            </g>
                          </svg>
                        </span>
                      </template>
                      Next
                    </gf-button>
                  </div>
                </div>
                <div v-if="$route.meta.mode === 'create'" :style="{ display: active === 2 ? 'block': 'none' }">
                  <gf-alert class="mb-8">
                    <template slot="icon">
                      <span class="svg-icon svg-icon-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <path d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z" fill="#000000" opacity="0.3"/>
                            <polygon fill="#000000" opacity="0.3" points="11.3333333 18 16 11.4 13.6666667 11.4 13.6666667 7 9 13.6 11.3333333 13.6"/>
                          </g>
                        </svg>
                      </span>
                    </template>
                    Input User's Account Details below
                  </gf-alert>
                  <el-form-item label="Username" prop="username">
                    <el-input v-model="user.username" placeholder="Username"></el-input>
                  </el-form-item>
                  <el-form-item label="Password" prop="password">
                    <el-input type="password" v-model="user.password" placeholder="Password"></el-input>
                  </el-form-item>
                  <el-form-item label="Retype Password" prop="retype_password">
                    <el-input type="password" v-model="user.retype_password" placeholder="Retype Password"></el-input>
                  </el-form-item>
                  <div class="control">
                    <gf-button class="mr-4" type="secondary" @click="active--">
                      <template slot="icon">
                        <span class="svg-icon svg-icon-sx">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <polygon points="0 0 24 0 24 24 0 24"/>
                              <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-12.000000, -12.000000) " x="11" y="5" width="2" height="14" rx="1"/>
                              <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) "/>
                            </g>
                          </svg>
                        </span>
                      </template>
                      Back
                    </gf-button>
                    <gf-button type="primary" @click="active++">
                      <template slot="icon">
                        <span class="svg-icon svg-icon-white svg-icon-sx">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <polygon points="0 0 24 0 24 24 0 24"/>
                              <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) " x="11" y="5" width="2" height="14" rx="1"/>
                              <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) "/>
                            </g>
                          </svg>
                        </span>
                      </template>
                      Next
                    </gf-button>
                  </div>
                </div>
              </el-form>
            </gf-form>
            <div class="transfer" :style="{ display: active === 3 ? 'flex': 'none' }">
              <div style="width: 576px">
                <gf-alert class="mb-8">
                  <template slot="icon">
                    <span class="svg-icon svg-icon-primary">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24"/>
                          <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                          <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                        </g>
                      </svg>
                    </span>
                  </template>
                  Select User's Group below.
                  <br>
                  <b>Note:</b> User will inherit group's permissions.
                </gf-alert>
              </div>
              <el-transfer :titles="['Groups', 'Selected Groups']" v-model="selectedGroups" :data="groups"></el-transfer>
              <div class="mt-5 control" style="width: 576px;">
                <gf-button class="mr-4" type="secondary" @click="$route.meta.mode === 'create' ? active-- : active -= 2">
                  <template slot="icon">
                    <span class="svg-icon svg-icon-sx">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24"/>
                          <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-12.000000, -12.000000) " x="11" y="5" width="2" height="14" rx="1"/>
                          <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) "/>
                        </g>
                      </svg>
                    </span>
                  </template>
                  Back
                </gf-button>
                <gf-button type="primary" @click="active++">
                  <template slot="icon">
                    <span class="svg-icon svg-icon-white svg-icon-sx">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24"/>
                          <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) " x="11" y="5" width="2" height="14" rx="1"/>
                          <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) "/>
                        </g>
                      </svg>
                    </span>
                  </template>
                  Next
                </gf-button>
              </div>
            </div>
            <div class="transfer" :style="{ display: active === 4 ? 'flex': 'none' }">
              <div style="width: 576px">
                <gf-alert class="mb-8">
                  <template slot="icon">
                    <span class="svg-icon svg-icon-primary">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"/>
                          <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                          <path d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z" fill="#000000"/>
                          <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                        </g>
                      </svg>
                    </span>
                  </template>
                  Select User's Permission below. You can control individual permission here.
                  <br>
                  <b>Note:</b> Group Permission and Individual Permission will be combined
                </gf-alert>
              </div>
              <el-transfer :titles="['Permissions', 'Selected Permissions']" v-model="selectedResources" :data="resources"></el-transfer>
              <div class="mt-5 control" style="width: 576px;">
                <gf-button class="mr-4" type="secondary" @click="active--">
                  <template slot="icon">
                    <span class="svg-icon svg-icon-sx">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24"/>
                          <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-12.000000, -12.000000) " x="11" y="5" width="2" height="14" rx="1"/>
                          <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) "/>
                        </g>
                      </svg>
                    </span>
                  </template>
                  Back
                </gf-button>
                <gf-button type="primary" @click="save">
                  <template slot="icon">
                    <span class="svg-icon svg-icon-white svg-icon-sx">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24"/>
                          <path d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z" fill="#000000" fill-rule="nonzero"/>
                          <rect fill="#000000" opacity="0.3" x="12" y="4" width="3" height="5" rx="0.5"/>
                        </g>
                      </svg>
                    </span>
                  </template>
                  Save
                </gf-button>
              </div>
            </div>
          </gf-container>
        </el-col>
      </el-row>
    </gf-card>
  </gf-base>
</template>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: row;
  height: 100%;

  &__nav {
    width: 35%;

    .nav__item {
      padding: 15px 20px;
      display: flex;
      flex-direction: row;
      border-radius: 5px;
      align-items: center;

      .label {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.control {
  text-align: right;
}

.image-input {
  position: relative;
  display: inline-block;
  border-radius: 5.46px;

  img {
    border: 3px solid #fff;
    width: 120px;
    height: 120px;
    box-shadow: 0px 6px 10px 6px rgba(0, 0, 0, 0.075);
    border-radius: 5.46px;
  }

  .tool {
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.25) !important;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #ffffff;
    border-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: #B5B5C3 !important;
      font-size: 12px;
    }

    &__change {
      position: absolute;
      top: -7px;
      right: -8px;

      input {
        width: 0 !important;
        height: 0 !important;
      }
    }

    &__remove {
      position: absolute;
      bottom: 7px;
      right: -8px;
    }
  }
}

.transfer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>

<script>
import PositionService from '@/services/v1/Position'
import ResourceService from '@/services/v1/Resource'
import GroupService from '@/services/v1/Group'
import UserService from '@/services/v1/User'

export default {
  data () {
    const checkUsername = async (rule, value, cb) => {
      try {
        const usService = new UserService()
        const response = await usService.list(null, null, { username: { '=': value } })

        if (response.data.count > 0) {
          cb(new Error('Username already exist'))
          return
        }

        cb()
      } catch (error) {
        this.$Error(error)
      }
    }
    const checkPassword = (rule, value, cb) => {
      if (value !== this.user.password) {
        cb(new Error('Password does not match'))
        return
      }

      cb()
    }
    const checkPassword2 = (rule, value, cb) => {
      if (value !== this.reset.password) {
        cb(new Error('Password does not match'))
        return
      }

      cb()
    }
    return {
      // ui
      title: '',
      subtitle: '',

      loading: false,
      selectPositionLoading: false,
      addPositionModalVisible: false,
      addPositionModalLoading: false,
      deactivateModalVisible: false,
      deactivateModalLoading: false,
      activateModalVisible: false,
      activateModalLoading: false,
      resetModalVisible: false,
      resetModalLoading: false,
      removeModalVisible: false,
      removeModalLoading: false,

      user: {
        fname: null,
        mname: null,
        lname: null,
        address: null,
        phone_number: null,
        email: null,
        position_id: null,
        avatar_url: null,
        username: null,
        password: null
      },
      reset: {
        password: null,
        retype_password: null
      },
      selectedGroups: [],
      selectedResources: [],

      position: {
        name: null
      },

      // fetch
      positions: [],
      groups: [],
      resources: [],

      // rules
      userRules: {
        fname: [
          { required: true, message: 'First name is require', trigger: 'blur' }
        ],
        mname: [
          { required: true, message: 'Middle name is require', trigger: 'blur' }
        ],
        lname: [
          { required: true, message: 'Last name is require', trigger: 'blur' }
        ],
        address: [
          { required: true, message: 'Address is require', trigger: 'blur' }
        ],
        position_id: [
          { required: true, message: 'Position is require', trigger: 'change' }
        ],
        phone_number: [
          { required: true, message: 'Phone number is require', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Email is require', trigger: 'blur' }
        ],
        username: [
          { required: true, message: 'Username is require', trigger: 'blur' },
          { min: 5, max: 20, message: 'Username must be between 5 to 20 characters', trigger: 'blur' },
          { validator: checkUsername, trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Password is require', trigger: 'blur' },
          { min: 5, max: 20, message: 'Password must be between 5 to 20 characters', trigger: 'blur' }
        ],
        retype_password: [
          { required: true, message: 'Retype Password is require', trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' }
        ]
      },
      passwordResetRules: {
        password: [
          { required: true, message: 'Password is require', trigger: 'blur' },
          { min: 5, max: 20, message: 'Password must be between 5 to 20 characters', trigger: 'blur' }
        ],
        retype_password: [
          { required: true, message: 'Retype Password is require', trigger: 'blur' },
          { validator: checkPassword2, trigger: 'blur' }
        ]
      },
      positionRules: {
        name: [
          { required: true, message: 'Position name is require', trigger: 'blur' }
        ]
      },

      active: 1
    }
  },
  methods: {
    // navigation
    back () {
      this.$router.back()
    },

    // main
    save () {
      this.$refs.user.validate()
        .then(async () => {
          try {
            this.loading = true

            const user = this.$_.cloneDeep(this.user)
            this.$Sanitize(user)

            const resource = []
            const group = []

            for (let i = 0; i < this.selectedGroups.length; i++) {
              const sgroup = this.selectedGroups[i]
              group.push({ group_id: sgroup })
            }

            for (let i = 0; i < this.selectedResources.length; i++) {
              const sresource = this.selectedResources[i]
              resource.push({ resource_id: sresource })
            }

            delete user.retype_password
            user.resource = resource
            user.group = group

            const id = this.$route.params.id
            if (this.$route.meta.mode === 'create') {
              const usService = new UserService()
              await usService.create(user)
              this.$message.success('User was successfully added')
            } else {
              const usService = new UserService(id)
              await usService.update(user)
              this.$message.success('User was successfully modified')
            }

            this.$router.push({ name: 'users-list' })
          } catch (error) {
            this.$Error(error)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {
          this.$notify.error({
            title: 'Failed',
            message: 'Please check required details.'
          })
        })
    },
    async activate () {
      try {
        this.activateModalLoading = true
        const usService = new UserService(this.$route.params.id)
        await usService.activate()

        this.$message.success('You have successfully Reactivate user')
        this.activateModalVisible = false
        this.getUser()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.activateModalLoading = false
      }
    },
    async deactivate () {
      try {
        this.deactivateModalLoading = true
        const usService = new UserService(this.$route.params.id)
        await usService.deactivate()

        this.$message.success('You have successfully deactivate user')
        this.deactivateModalVisible = false
        this.getUser()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.deactivateModalLoading = false
      }
    },
    resetPassword () {
      this.$refs.resetPassword.validate()
        .then(async () => {
          try {
            this.resetModalLoading = true

            const reset = this.$_.cloneDeep(this.reset)
            delete reset.retype_password

            const usService = new UserService(this.$route.params.id)
            await usService.resetPassword(reset)

            this.resetModalVisible = false
            this.$message.success('You have successfully reset user password')
            this.$ClearField('resetPassword')
          } catch (error) {
            this.$Error(error)
          } finally {
            this.resetModalLoading = false
          }
        })
        .catch(() => {})
    },
    async remove () {
      try {
        this.removeModalLoading = true
        const usService = new UserService(this.$route.params.id)
        await usService.del()

        this.$message.success('You have successfully deleted a user')
        this.removeModalVisible = false
        this.$router.push({ name: 'users-list' })
      } catch (error) {
        this.$Error(error)
      } finally {
        this.removeModalLoading = false
      }
    },

    // file
    handleAvatarChange () {
      this.$refs.avatarInputFile.click()
    },
    handleClearAvatar () {
      this.user.avatar_url = ''
    },
    async handleFileChange (e) {
      try {
        this.loading = true
        const img = e.target.files[0]
        const fd = new FormData()
        fd.append('image', img)

        const usService = new UserService()
        const result = await usService.uploadAvatar(fd)
        this.user.avatar_url = result.data.path
      } catch (error) {
        this.$Error(error)
      } finally {
        this.$refs.avatarInputFile.value = null
        this.loading = false
      }
    },

    // modal
    newPosition () {
      this.$refs.position.validate()
        .then(async () => {
          try {
            this.addPositionModalLoading = true

            const psService = new PositionService()
            await psService.create(this.position)
            this.$notify.success('Position successfully added')

            this.getPositions()
            this.addPositionModalVisible = false
          } catch (error) {
            this.$Error(error)
          } finally {
            this.addPositionModalLoading = false
          }
        })
        .catch(() => {})
    },

    async getPositions (query) {
      try {
        const filter = {}
        this.selectPositionLoading = true
        if (query === '' || !query) {
          delete filter.q
        } else {
          filter.q = {
            like: query
          }
        }

        const psService = new PositionService()
        const response = await psService.list(null, null, filter)

        this.positions = response.data.rows
      } catch (error) {
        this.$Error(error)
      } finally {
        this.selectPositionLoading = false
      }
    },
    async getResources () {
      try {
        const rsService = new ResourceService()
        const response = await rsService.list({ size: 1000, number: 1 })

        for (let i = 0; i < response.data.rows.length; i++) {
          const rs = response.data.rows[i]
          this.resources.push({ key: rs.id, label: `${rs.code} (${rs.permission})` })
        }
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      }
    },
    async getGroups () {
      try {
        const gpService = new GroupService()
        const response = await gpService.list({ size: 1000, number: 1 })

        for (let i = 0; i < response.data.rows.length; i++) {
          const grp = response.data.rows[i]
          this.groups.push({ key: grp.id, label: grp.name })
        }
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      }
    },
    async getUser () {
      try {
        this.selectedGroups = []
        this.selectedResources = []

        const usService = new UserService(this.$route.params.id)
        const response = await usService.get()

        this.user = response.data

        await this.getPositions(this.user.position.name)

        for (let i = 0; i < this.user.groups.length; i++) {
          const group = this.user.groups[i]
          this.selectedGroups.push(group.id)
        }

        for (let i = 0; i < this.user.resources.length; i++) {
          const resource = this.user.resources[i]
          this.selectedResources.push(resource.id)
        }

        delete this.user.groups
        delete this.user.resources
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      }
    }
  },
  async mounted () {
    await this.getGroups()
    await this.getResources()
    await this.getPositions()

    if (this.$route.meta.mode === 'create') {
      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Settings' },
        { title: 'Company Details' },
        { title: 'Users' },
        { title: 'Create' }
      ])

      this.title = 'New User'
      this.subtitle = 'Create New User'
    } else {
      await this.getUser()

      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Settings' },
        { title: 'Company Details' },
        { title: 'Users' },
        { title: this.user.fname + ' ' + this.user.lname }
      ])

      this.title = this.user.fname + ' ' + this.user.lname
      this.subtitle = 'Modify User Information'
    }
  }
}
</script>
